//dual-horizontal
.dual-horizontal{
    background: var(--#{$variable-prefix}primary);
    .nav{
        background: var(--#{$variable-prefix}primary);
 
    }
}

//boxed

    .boxed{
        background: var(--#{$variable-prefix}primary);
    }


//boxed-fancy
.boxed-fancy{
    &::before{
        background: var(--#{$variable-prefix}primary);
    }
}