.btn {
    border-radius: $btn-border-radius;
}
.btn-xs {
    border-radius: $btn-border-radius-xs;
}
.btn-sm {
    border-radius: $btn-border-radius-sm;
}
.btn-lg {
    border-radius: $btn-border-radius-lg;
}