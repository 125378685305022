.dual-compact {
    padding-top: 58px;
}
@media all and (max-width: 1024px) {
    .dual-compact {
        padding-top: 90px;
    }
}
@media all and (max-width: 991px) {
    .dual-compact {
        padding-top: 65px;
    }
}
