.sidebar.sidebar-mini {
	+ {
		.main-content {
			transition: all 300ms ease;
			margin-right: 4.8rem;
            margin-left: unset;
		}
	}
    .navbar-brand{
        .logo-title{
            transform: translateX(100%) scale(0);
        }
    }
}
@include media-breakpoint-down(xl) {
    .sidebar {
        &.sidebar-default {
            &.sidebar-mini {
                transform: translateX(100%);
                .sidebar-header {
                    a.navbar-brand {
                        transform: translate(100%);
                    }
                }
            }
        }
    }
    .sidebar {
        &.sidebar-default.sidebar-mini+.main-content{
            margin-right: 0;
            margin-left: unset;
        }
    }
}

@include media-breakpoint-down(xl) {
    .sidebar {
        .sidebar-toggle {
            left: 18px;
        }
    }
}

.navs-rounded-all .navbar-nav .nav-item:not(.static-item){
    padding-right: $spacer;
    padding-left: unset;
}
.navs-rounded-all {
    .sidebar-body {
        padding-right: unset;
        padding-left: 1rem;
    }
}